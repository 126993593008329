import {Instance, types} from "mobx-state-tree";
import React, {createContext, useContext} from "react";
import {AuthStore} from "./AuthStore";
import {DataStore} from "./DataStore";

type RootStoreType = Instance<typeof RootStore>

interface Props {
  children: React.ReactNode;
}

const RootStore = types.model({
  auth: AuthStore,
  data: DataStore
}).actions(self => ({
  init: async () => {
    await self.auth.init()
    await self.data.init()
  }
}));

const RootStoreContext = createContext({} as RootStoreType);

const RootStoreProvider = ({children}: Props) => {
  const stores = RootStore.create({
    auth: AuthStore.create(),
    data: DataStore.create()
  });
  return (
    <RootStoreContext.Provider value={stores}>
      {children}
    </RootStoreContext.Provider>
  );
};

type RootStoreKeys = keyof RootStoreType;

function useRootStore<K extends RootStoreKeys>(param?: K): RootStoreType[K] {
  const stores = useContext(RootStoreContext);

  return param? stores[param]: stores;
}

export {useRootStore, RootStoreProvider};
