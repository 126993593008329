import {Outlet, Route, Routes} from 'react-router-dom'
import React from "react";

//
const Dashboard = React.lazy(() => import('../../pages/admin/Dashboard'))
const Beacon = React.lazy(() => import ( '../../pages/admin/Beacon'))
const Bundle = React.lazy(() => import ( '../../pages/admin/Bundle'))
const Evacuation = React.lazy(() => import ( '../../pages/admin/Evacuation'))
const Line = React.lazy(() => import ( '../../pages/admin/Line'))
const Obstacle = React.lazy(() => import ( '../../pages/admin/Obstacle'))
const PoiTopologyVersion = React.lazy(() => import ( '../../pages/admin/PoiTopologyVersion'))
const Station = React.lazy(() => import ( '../../pages/admin/Station'))
const SubwayOrganization = React.lazy(() => import ( '../../pages/admin/SubwayOrganization'))
const UserCategory = React.lazy(() => import ( '../../pages/admin/UserCategory'))
const NotFoundScreen = React.lazy(() => import ( '../../pages/NotFoundScreen'))
const ObstacleMap = React.lazy(() => import ( "../../pages/admin/ObstacleMap"))
const ThemeTrip = React.lazy(() => import ( "../../pages/admin/ThemeTrip"))
const Qna = React.lazy(() => import ( '../../pages/admin/Qna'))
const Notice = React.lazy(() => import ( "../../pages/admin/Notice"))
const SignLanguageVideo = React.lazy(() => import("../../pages/admin/SignLanguageVideo"))
const PoiTopology3dDebugger = React.lazy(() => import("../../pages/admin/PoiTopology3dDebugger"))


const AdminRoute = () => {
  return (
    <React.Suspense fallback={null}>
      <Routes>
        <Route path="/*" element={<Outlet/>}>
          <Route index element={<Dashboard/>}/>
          <Route path="*" element={<NotFoundScreen/>}/>
        </Route>
        <Route path="/subway-organization/*" element={<Outlet/>}>
          <Route index element={<SubwayOrganization/>}/>
          <Route path="line" element={<Line/>}/>
          <Route path="station" element={<Station/>}/>
          <Route path='bundle' element={<Bundle/>}/>
          <Route path='beacon' element={<Beacon/>}/>
          <Route path='poi-topology-version' element={<Outlet/>}>
            <Route index element={<PoiTopologyVersion/>}/>
            <Route path={"3d-model"} element={<PoiTopology3dDebugger/>}/>
            <Route path="*" element={<NotFoundScreen/>}/>
          </Route>
          <Route path='evacuation' element={<Evacuation/>}/>
          <Route path="*" element={<NotFoundScreen/>}/>
        </Route>
        <Route path="user-category" element={<UserCategory/>}/>
        <Route path="sign-language-video" element={<SignLanguageVideo/>}/>
        <Route path="obstacle/*" element={<Outlet/>}>
          <Route index element={<Obstacle/>}/>
          <Route path='map' element={<ObstacleMap/>}/>
          <Route path="*" element={<NotFoundScreen/>}/>
        </Route>
        <Route path="theme-trip/*" element={<Outlet/>}>
          <Route index element={<ThemeTrip/>}/>
          <Route path="*" element={<NotFoundScreen/>}/>
        </Route>
        <Route path="customer-support/*" element={<Outlet/>}>
          <Route path={"qna"} element={<Qna/>}/>
          <Route path={"notice"} element={<Notice/>}/>
          <Route path="*" element={<NotFoundScreen/>}/>
        </Route>
        <Route path="*" element={<NotFoundScreen/>}/>
      </Routes>
    </React.Suspense>
  )
}

export default AdminRoute
