import { api } from '../api/api'
import React from 'react'

export interface AsyncListParams {
  ob?: string
  od?: string
  limit?: number | undefined | null
  offset?: number
  search?: string

  [key: string]: any
}

const DefaultParams: AsyncListParams = {
  ob: 'ID',
  od: 'DESC',
  limit: 20,
  offset: 0,
  search: ""
}

interface IObject<T> {
  data: T | null,
  isLoading: boolean
}

const useData = <T>(basePath: string, initialParams: AsyncListParams = DefaultParams) => {
  const [params, setParams] = React.useState({...DefaultParams, ...initialParams})
  const fetch = (newParams?: AsyncListParams) => setParams(cur => ({ ...cur, ...newParams }))
  const onPaginationChange = (page: number) => fetch({ offset: (page - 1) * (params.limit || 20) })
  const [object, setObject] = React.useState<IObject<T>>({
    data: null,
    isLoading: true
  })


  React.useEffect( () => {
    setObject(cur => ({...cur, isLoading: true}))
    api.get<T>(basePath, params).then(res => {
      if (!res.ok || !res.data) {
        // toast.error(String(res.status))
        return
      }

      setObject({data: res.data, isLoading: false})
    }).finally(() => {
      setObject(cur => ({...cur, isLoading: false}))
    })

  }, [params, basePath])

  return {
    ...object,
    fetch,
    refetch: fetch,
    onPaginationChange
  }
}

export default useData
