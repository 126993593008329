import LottiePlayer from 'lottie-react'
import NotFound from '../resources/admin/lottie/404.json'
import Button from '../components/common/Button'
import {Link} from 'react-router-dom'
import {Helmet} from 'react-helmet-async'
import Paper from '@mui/material/Paper'
import Text from '../components/common/Text'


const NotFoundScreen = () => {
  return (
    <Paper sx={{
      width: "100%",
      position: "absolute",
      top: "0px",
      bottom: "0px",
      left: "0px",
      right: "0px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",

      '& h5': {
        fontSize: "26px",
        fontWeight: "bold",
      },

      "& p": {
        fontSize: "20px",
        maxWidth: "700px",
        textAlign: "center"
      },

      "& button":  {
        fontSize: "20px",
        padding: "10px 50px"
      }
    }}>
      <Helmet>
        <title>찾을 수 없는 페이지</title>
      </Helmet>
      <LottiePlayer animationData={NotFound} loop style={{height: '200px'}}/>
      <Text variant="h4" fontWeight={'bold'}>
        죄송합니다. 현재 찾을 수 없는 페이지를 요청 하셨습니다.
      </Text>
      <Text mt={2}>
        존재하지 않는 주소를 입력하셨거나, 요청하신 페이지의 주소가 변경,
        삭제되어 찾을 수 없습니다. 궁금한 점이 있으시면 언제든 고객센터를 통해
        문의해 주시기 바랍니다. 감사합니다.
      </Text>
      <Link to={''}>
        <Button sx={{mt: 5}}>메인으로 가기</Button>
      </Link>
    </Paper>
  )
}

export default NotFoundScreen
