import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { RequireAuth } from '../components/RequireAuth'
import AdminRoute from './admin/AdminRoute'
import AdminLayout from '../components/common/AdminLayout'
import ScrollToTop from '../utils/ScrollToTop'
import { useRootStore } from '../models/RootStore'
import Toaster from '../components/utilities/Toaster'
import { usePalleteMode } from '../providers/PalleteProvider'
import { theme } from '../utils/theme'
import useEffectOnce from '../hooks/useEffectOnce'
import { NavermapsProvider } from 'react-naver-maps'
import SignIn from '../pages/auth/SignIn'
import React from 'react'
import Loading from '../components/common/Loading'
import {ThemeProvider} from '@mui/material/styles'
import CssBaseline from "@mui/material/CssBaseline";
import Home from '../pages/Home'
import NotFoundScreen from '../pages/NotFoundScreen'
import MobileRoute from '../pages/Route'


const RootRouter = observer(() => {
  const rootStore = useRootStore()
  const palette = usePalleteMode()
  const [initialLoad, setInitialLoad] = React.useState(true)

  useEffectOnce(() => {
    rootStore.init().then(() => setInitialLoad(false))
  })

  if (initialLoad) return <Loading />


  return (
    <ThemeProvider theme={theme(palette.mode)}>
      <CssBaseline enableColorScheme>
        <BrowserRouter>
          <ScrollToTop />
          <Toaster />
          <NavermapsProvider ncpClientId='wakbd4kofz'>
            <Routes key={String(rootStore.auth.isAuthenticated)}>
              <Route index element={<Home/>} />
              <Route path={'route'} element={<MobileRoute />} />
              <Route path={'sign-in'} element={<SignIn />} />
              <Route path={'admin/*'} element={
                <RequireAuth isAuthenticated={rootStore.auth.isAuthenticated}>
                  <AdminLayout>
                    <AdminRoute />
                  </AdminLayout>
                </RequireAuth>
              } />

              <Route path={'*'} element={<NotFoundScreen />} />
            </Routes>
          </NavermapsProvider>
        </BrowserRouter>
      </CssBaseline>

    </ThemeProvider>
  )
})

export default RootRouter
