import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const Loading = () => {
  return (
    <div
      style={{
        position: 'relative',
        width: "100%",
        height: "100%"
      }}
    >
      <Backdrop open sx={{
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: "absolute",
        backgroundColor: "transparent",
        backdropFilter: "blur(10px)"
      }}>
        <CircularProgress/>
      </Backdrop>
    </div>
  )
}

export default Loading
