import {ApisauceConfig, create} from 'apisauce'
import axios from 'axios'

// const baseURL = 'http://localhost:3001/v1'
const baseURL = 'https://api.subway-navigation.zeroweb.kr/v1'

const axiosInstance = axios.create({timeout: 30000, baseURL})

// Add a request interceptor
axiosInstance.interceptors.request.use(async (config) => {
  // const httpMetric = firebase.perf().newHttpMetric(config.url, config.method.toUpperCase());
  // config.metadata = { httpMetric };

  // add any extra metric attributes if needed
  // await httpMetric.putAttribute('baseURL', config.baseURL);
  // await httpMetric.putAttribute('User-Agent', config.headers['User-Agent']);
  // await httpMetric.start();
  return config
})

axiosInstance.interceptors.response.use(
  async (response) => {
    // Request was successful, e.g. HTTP code 20
    // console.log('axios.response:onFulfilled():response', response)
    // const { httpMetric } = response.config.metadata;

    // add any extra metric attributes if needed
    // httpMetric.putAttribute('baseURL', baseURL);

    // await httpMetric.setHttpResponseCode(response.status);
    // await httpMetric.setResponseContentType(response.headers['content-type']);
    // await httpMetric.stop();

    return response
  },
  async (error) => {
    // Request failed, e.g. HTTP code 500
    // console.warn('axios.response:onRejected():error', error)
    // const { httpMetric } = error.config.metadata;

    // add any extra metric attributes if needed
    // httpMetric.putAttribute('userId', '12345678');

    // await httpMetric.setHttpResponseCode(error.response.status);
    // await httpMetric.setResponseContentType(error.response.headers['content-type']);
    // await httpMetric.stop();

    return Promise.reject(error)
  }
)

export const api = create({axiosInstance} as ApisauceConfig)
// export const api = create({
//   baseURL,
// });
