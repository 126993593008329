import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import MenuIcon from '@mui/icons-material/Menu'
import Paper from '@mui/material/Paper'
import React from 'react'
import {IMenu, NavigationMenu} from "../../constants/navigation";
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Collapse from '@mui/material/Collapse'
import {NavLink} from "react-router-dom";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {usePalleteMode} from "../../providers/PalleteProvider";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import {hasChildren} from '../../utils/nav-utils';
import Grid from '../common/Grid';


const SideBarExperimental = () => {
  const [sideOpen, setSideOpen] = React.useState(true)
  const pallete = usePalleteMode()

  return (
    <Paper square elevation={0} style={{
      overflowY: "scroll",
      maxHeight: "100vh"
    }}>
        <ListItemButton sx={{minHeight: '70px', position: "sticky", top: 0, zIndex: 10}} style={{backgroundColor: pallete.mode==="dark"? "#000": "#fff"}} onClick={() => setSideOpen(!sideOpen)}>
          <ListItemIcon sx={{ml: 'auto', display: 'flex', justifyContent: 'center', }}>
            {sideOpen ? <ChevronLeftIcon/> : <MenuIcon/>}
          </ListItemIcon>
        </ListItemButton>
      {NavigationMenu.map((m) => <MenuItem key={m.id} item={m} collapsed={sideOpen}/>)}
    </Paper>
  )
}

interface Props {
  item: IMenu
  isChild?: boolean
  collapsed: boolean
}

const MenuItem = (props: Props) => {
  const Component = hasChildren(props.item) ? MultiLevel : SingleLevel;
  return <>
    <Component  {...props} />
  </>
};

const SingleLevel = ({item, isChild, collapsed}: Props) => {
  const colorScheme = usePalleteMode()
  const [active, setActive] = React.useState(false)

  return (
    <Tooltip title={!collapsed ? item.label : ""} placement={"right"}>
      <NavLink
        end={true}
        to={item.to}
        style={({isActive}) => {
          setActive(isActive)
          return {
            color: "inherit",
            display: "flex",
            alignItems: "center",
            height: "50px",
            margin: "2px 5px",
          }
        }}
      >
      <ListItemButton sx={{
        backgroundColor: active ? "rgba(254,220,33,0.32)" : "transparent",
        color : colorScheme.mode === "dark" ? active ? "#ffdc21": "#ccc" : "",
        borderRadius: "10px",
        height: '100%'
      }}>

        <ListItemIcon>{<item.icon style={{color: colorScheme.mode === "dark"? active? "#e0c21c": "inherit": "inherit"}}/>}</ListItemIcon>
        <Collapse in={collapsed} orientation={"horizontal"}>
          <ListItemText sx={{whiteSpace: 'nowrap'}} primary={item.label}/>
        </Collapse>
      </ListItemButton>
      </NavLink>
    </Tooltip>
  );
};

const MultiLevel = ({item, isChild, collapsed}: Props) => {
  const {children} = item;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <Tooltip title={!collapsed ? item.label : ""} placement={"right"}>
        <ListItemButton
          sx={{  borderRadius: "10px", m: "2px 5px"}}
          onClick={() => setOpen((prev) => !prev)}
        >
          <ListItemIcon>{<item.icon/>}</ListItemIcon>
          <Collapse in={collapsed} orientation={"horizontal"} sx={{flex: 1}}>
            <ListItemText sx={{whiteSpace: 'nowrap'}} primary={item.label}/>
          </Collapse>
          {open ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
        </ListItemButton>
      </Tooltip>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <Grid container>
          <Grid container width={"25px"} justifyContent={"end"}>
            <Divider orientation='vertical'/>
          </Grid>
        <List component="div" disablePadding style={{flex: 1}}>
          {children.map((child) => (
            <MenuItem key={child.id} item={child} isChild collapsed={collapsed}/>
          ))}
        </List>
        </Grid>
      </Collapse>

    </React.Fragment>
  );
};

export default SideBarExperimental
