import { Toaster as T } from 'react-hot-toast'
import { PRIMARY_COLOR } from '../../constants/colors'

const Toaster = () => {
  return (
    <T
      position="top-center"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: '',
        duration: 3000,
        style: {
          background: '#000',
          color: '#fff',
        },
        success: {
          duration: 3000,
          style: {
            background: '#fff',
            color: '#333',
            border: `1px solid ${PRIMARY_COLOR}`,
          },
        },
        error: {
          style: {
            background: '#fff',
            color: '#333',
            border: '1px solid red',
          },
        },
      }}
    />
  )
}

export default Toaster
