import InputAdornment from "@mui/material/InputAdornment";
import {observer} from "mobx-react-lite";
import React from "react";
import { useForm} from "react-hook-form";
import Button from "../../components/common/Button";
import {useRootStore} from "../../models/RootStore";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Text from "../../components/common/Text";
import Grid from "../../components/common/Grid"
import Paper from "@mui/material/Paper";
import {  useTheme } from '@mui/material/styles'
import { Navigate, useNavigate } from 'react-router-dom'
import Input from '../../components/common/Input'

interface Inputs {
  username: string;
  password: string;
}



const SignIn = observer(() => {
  const authStore = useRootStore("auth");
  const hookForm = useForm<Inputs>();
  const [pswShow, setPswShow] = React.useState(false);
  const theme = useTheme()
  const navigate = useNavigate()
  const onSuccess = () => navigate("/admin", {replace: true, state: {}});
  const onFail = () => hookForm.reset();

  if (authStore.isAuthenticated) {
    return <Navigate to={"/admin"}/>
  }

  const onSignIn = hookForm.handleSubmit(async ({username, password}) => {
    await authStore.login({
      username,
      password,
      onSuccess,
      onFail,
    });
  })

  return (
    <Paper style={{
      position: "fixed",
      top: "0",
      bottom: "0",
      left: "0",
      right: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column"
    }}>
      <Grid display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Text sx={{color: theme.palette.text.primary, fontSize: "20px"}}>스마트시티 챌린지 본사업</Text>
        <Text sx={{color: theme.palette.text.primary, fontSize: "40px", fontWeight: "bold"}}>실내 내비게이션</Text>
      </Grid>
      <div style={{
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        width: "450px",
        margin: "50px 0"
      }}>
        <form onSubmit={onSignIn}>
          <Input
            style={{ fontSize: "18px", marginTop: "30px"}}
            autoFocus
            type="text"
            {...hookForm.register("username", {required: "아이디 일치하지 않습니다"})}
            name="username"
            label="아이디입력"
            variant={"filled"}
            error={!!hookForm.formState.errors.username}
            helperText={hookForm.formState.errors.username ? hookForm.formState.errors.username.message : ""}
            fullWidth
            size={"medium"}
          />

          <Input
            type={pswShow ? "text" : "password"}
            {...hookForm.register("password", {required: "비밀번호가 일치하지 않습니다"})}
            name="password"
            label="패스워드 입력"
            error={!!hookForm.formState.errors.password}
            helperText={hookForm.formState.errors.password ? hookForm.formState.errors.password.message : ""}
            fullWidth
            variant={"filled"}
            size={"medium"}
            InputProps={
              {
                endAdornment: <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setPswShow(!pswShow)}
                    onMouseDown={e => e.preventDefault()}
                    edge="end"
                  >
                    {pswShow ? <VisibilityOff/> : <Visibility/>}
                  </IconButton>
                </InputAdornment>
              }
            }
          />

          <Button
            type="submit"
            loading={authStore.isLoading}
            fullWidth
            style={{
              marginTop: "60px",
              height: "60px",
              fontSize: "20px",
              fontWeight: "bold",
            }}
          >
            로그인
          </Button>
        </form>
      </div>
    </Paper>
  );
});

export default SignIn;
