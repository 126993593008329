
const PREFIX = "/admin"

export const ENDPOINTS = {
  organization: `${PREFIX}/subway-organization`,
  line: `${PREFIX}/line`,
  station: `${PREFIX}/station`,
  beacon: `${PREFIX}/beacon`,
  bundle: `${PREFIX}/bundle`,
  poiTopologyVersion: `${PREFIX}/poi-topology-version`,
  evacuation: `${PREFIX}/evacuation`,
  userCategory: `${PREFIX}/user/category`,
  obstacle: `${PREFIX}/obstacle`,
  signLanguageVideo: `${PREFIX}/sign-language-video`,
  themeTripVersion: `${PREFIX}/theme-trip/version`,
  qnaQuestion: `${PREFIX}/qna/question`,
  qnaAnswer: `${PREFIX}/qna/answer`,
  notice: `${PREFIX}/notice`
};