import { Link, useSearchParams } from 'react-router-dom'
import Button from '../components/common/Button'
import Container from '@mui/material/Container'
import Text from '../components/common/Text'
import Grid from '../components/common/Grid'
import { PRIMARY_COLOR } from '../constants/colors'
import { ReactComponent as Direction } from '../resources/direction.svg'
import { ReactComponent as Logo } from '../resources/admin/icons/analytics-logo.svg'
import { ReactComponent as NotFoundLogo } from '../resources/not-found.svg'
import { ReactComponent as LoadingMascot } from '../resources/loading-mascot.svg'


import useData from '../hooks/useData'
import { Poi } from 'subway-navigation-core'
import Card from '@mui/material/Card'
import Chip from '@mui/material/Chip'
import React from 'react'
import Skeleton from '@mui/material/Skeleton'

interface RouteData {
  uuid: string;
  id: number;
  departurePoiId: number;
  destinationPoiId: number;
}

export default function Route() {
  const [searchParams] = useSearchParams()
  const uuid = searchParams.get('uuid')
  const route = useData<RouteData>(`/pluxity/${uuid}`)

  return (
    <Container style={{ paddingTop: '10px', backgroundColor: PRIMARY_COLOR, height: '100vh' }} maxWidth={false}>
      <Grid container justifyContent={'flex-end'} marginTop={'100px'}>
        <Logo />
      </Grid>
      <Grid container alignItems={'center'} justifyContent={'center'} flexDirection={'column'} rowGap={3} marginTop={'-100px'}>
        <Text  sx={{alignSelf: "flex-start", typography: { sm: 'h2', xs: 'h4' }, fontWeight: 'bold', color: '#333' }}>
          지하철 <br /> 내비게이션
        </Text>
        {(route.isLoading)
          ? <Loading />
          : !route.data
            ? <NotFound />
            : <FromTo departurePoiId={route.data.departurePoiId} destinationPoiId={route.data.destinationPoiId}/>
        }
        <Link to={window.location.href} style={{ width: '100%' }}>
          <Button color={'secondary'} fullWidth style={{ fontSize: '1.5rem', height: '70px' }}>
            앱에서 계속하기
          </Button>
        </Link>
      </Grid>

    </Container>
  )
}

interface FromToProps {
  departurePoiId: number;
  destinationPoiId: number
}

function FromTo(props: FromToProps) {
  const departurePoi = useData<Poi>(`/poi/${props.departurePoiId}`)
  const destinationPoi = useData<Poi>(`/poi/${props.destinationPoiId}`)

  return (
    <Card sx={{ padding: {xs: "10px 10px", sm: "20px 40px"}, margin: 0, backgroundColor: '#ecc500', width: '100%', display: "flex", alignItems: "center"  }}>
      <Grid container alignItems={'center'} width={'100%'} color={'#000000'} justifyContent={'center'} style={{flexWrap: "nowrap"}}>
        <Grid>
          <Chip label={'출발지'} color={'error'} />
          {!departurePoi.isLoading ? <>
            <Text sx={{ typography: { sm: 'body1', xs: 'body2' } }}>{departurePoi.data?.poiTopologyVersion?.station?.nameKo}</Text>
            <Text sx={{ color: '#333', typography: { sm: 'body1', xs: 'caption' } }}>{departurePoi.data?.nameKo}</Text>
          </> : <Skeleton height={'50px'}  width={"100px"} />
          }
        </Grid>
        <Grid container flex={1} justifyContent={"center"}>
          <Direction height={'80px'} />
        </Grid>
        <Grid>
          <Chip label={'도착지'} color={'secondary'} />
          {!destinationPoi.isLoading
            ? <>
              <Text sx={{ typography: { sm: 'body1', xs: 'body2' } }}>{destinationPoi.data?.poiTopologyVersion?.station?.nameKo}</Text>
              <Text sx={{ color: '#333', typography: { sm: 'body1', xs: 'caption' } }}>{destinationPoi.data?.nameKo}</Text>
            </>
            : <Skeleton height={'50px'} width={"100px"}/>
          }
        </Grid>
      </Grid>
    </Card>
  )
}

function NotFound () {
  return (
    <Card sx={{ padding: {xs: "10px 10px", sm: "20px 40px"}, margin: 0, backgroundColor: '#ecc500', width: '100%'  }}>
      <Grid container alignItems={'center'} width={'100%'} color={'#000000'} justifyContent={'center'} style={{flexWrap: "nowrap"}}>
        <Grid container flexDirection={"column"} alignItems={"center"}>
          <NotFoundLogo  height={'80px'}  />
          <Text>경로를 찾을 수 없습니다</Text>
        </Grid>
      </Grid>
    </Card>
  )
}


function Loading () {
  return (
    <Card sx={{ padding: {xs: "10px 10px", sm: "20px 40px"}, margin: 0, backgroundColor: '#ecc500', width: '100%' }}>
      <Grid container alignItems={'center'} width={'100%'} color={'#000000'} justifyContent={'center'} style={{flexWrap: "nowrap"}}>
        <Grid container flexDirection={"column"} alignItems={"center"}>
          <LoadingMascot  height={'80px'}  />
          <Text>경로 검색 중...</Text>
        </Grid>
      </Grid>
    </Card>
  )
}