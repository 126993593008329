import { HelmetProvider } from 'react-helmet-async'
import { RootStoreProvider } from './models/RootStore'
import RootRouter from './routes/RootRoute'
import { PalleteProvider } from './providers/PalleteProvider'

function App() {
  return (
      <PalleteProvider>
        <RootStoreProvider>
          <HelmetProvider>
            <RootRouter/>
          </HelmetProvider>
        </RootStoreProvider>
      </PalleteProvider>
  )
}

export default App
