import React from 'react'

type Mode = 'dark' | 'light'

interface ContextProps {
  mode: Mode
  toggleMode: () => void
}

const Context = React.createContext<ContextProps>({} as ContextProps)

interface Props {
  children: React.ReactNode
}

export const PalleteProvider = (props: Props) => {
  const [mode, setMode] = React.useState<Mode>(() => {
    const mode = (localStorage.getItem('mode') as Mode) || 'dark'
    document.body.style.backgroundColor = mode === "dark" ? "#121212" : "#ffffff"

    return mode
  })
  const toggleMode = () => {
    const newMode = mode === 'dark' ? 'light' : 'dark'
    localStorage.setItem('mode', newMode)
    setMode(newMode)
  }

  return (
    <Context.Provider value={ { mode, toggleMode } }>
      { props.children }
    </Context.Provider>
  )
}

export const usePalleteMode = () => React.useContext(Context)
