import {IMenu} from "../constants/navigation";

export function hasChildren(item: IMenu) {
  const {children} = item;

  if (children === undefined) {
    return false;
  }

  if (children.constructor !== Array) {
    return false;
  }

  return children.length !== 0;
}