import {flow, types} from 'mobx-state-tree'
import {api} from '../api/api'
import {StorageUtils} from '../utils/StorageUtils'
import toast from 'react-hot-toast'
import decode from 'jwt-decode'

interface LoginProps {
  username: string
  password: string
  keepSession?: boolean
  onSuccess: VoidFunction
  onFail: VoidFunction
}

const UserModel = types.model({
  uuid: types.optional(types.string, ''),
  id: types.optional(types.number, 0),
  username: types.optional(types.string, ''),
  iat: types.number,
  nbf: types.number,
  exp: types.number,
  type: types.optional(types.string, ''),
  createdAt: types.maybeNull(types.string),
  updatedAt: types.maybeNull(types.string),
})

export const AuthStore = types
  .model({
    user: types.maybeNull(UserModel),
    token: types.optional(types.string, ''),
    isAuthenticated: types.optional(types.boolean, false),
    isLoading: types.optional(types.boolean, false),
  })
  .actions(self => ({
    checkSession: flow(function* () {
      const res = yield api.get("/auth/session")
      return res.ok
    }),
    setAuthorization(token: string) {
      api.setHeader('Authorization', token)
      StorageUtils.setAuthorization(token, true)
      const user = decode(token) as typeof self.user
      self.isAuthenticated = true
      self.user = user
      self.token = token
    },
    removeAuthorization() {
      StorageUtils.removeAuthorization()
      api.deleteHeader("Authorization")
      self.isAuthenticated = false
      self.user = null
      self.token = ""
    }
  }))
  .actions((self) => ({
    init: flow(function* () {
      const token = StorageUtils.getAuthorization() || ''
      api.setHeader('Authorization', token)
      const activeSession = yield self.checkSession()
      activeSession ? self.setAuthorization(token) : self.removeAuthorization()
    }),
  }))
  .actions((self) => ({
    login: flow(function* ({username, password, keepSession = true, onSuccess, onFail}: LoginProps) {
      self.isLoading = true
      const res = yield api.post<{ token: string }>('/auth/sign-in', {
        username,
        password,
      })
      if (!res.ok || !res.data) {
        self.isLoading = false
        if (res.status === 401)
          toast.error('아이디 또는 비밀번호가 정확하지 않습니다')
        return onFail()
      }
      self.setAuthorization(res.data.token)
      self.isLoading = false
      onSuccess()
      toast.success('성공적으로 로그인했습니다')
    }),

    logout: (callback: VoidFunction) => {
      self.removeAuthorization();
      callback()
    },
    afterCreate: () => self.init(),
  }))

