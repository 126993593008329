import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

const AppWithCallbackAfterRender = () => <App />;

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<AppWithCallbackAfterRender />);

reportWebVitals(App);
