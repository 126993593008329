import { ThemeOptions, createTheme } from '@mui/material/styles'
import { baseTheme } from '../constants/base-theme'

export const darkOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#FEDC21',
    },
    secondary: {
      main: '#007BFE',
    },
  },
}

export const lightOptions: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#ecc81e',
    },
    secondary: {
      main: '#007BFE',
    },
  },
}

export const theme = (mode: 'dark' | 'light') => createTheme({...mode === 'dark' ? darkOptions : lightOptions, ...baseTheme})
