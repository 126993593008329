import Paper from '@mui/material/Paper'
import MuiContainer from '@mui/material/Container'
import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../admin/Header'
import Grid from './Grid'
import SideBarExperimental from "../admin/SideBarExperimental";
import Divider from '@mui/material/Divider'
import { usePalleteMode } from '../../providers/PalleteProvider'


interface Props {
  children: React.ReactNode
}

const AdminLayout = ({ children }: Props) => {
  const theme = usePalleteMode()
  return (
    <Paper square sx={{
      height: "100%",
      backgroundColor: theme.mode === "dark"? "#000": "#dedede"
    }}>
      <Grid container height={ '100%' }>
        <SideBarExperimental/>
        <Divider orientation={"vertical"}/>
        <Grid flex={ 1 } position="relative" >
          <Header/>
          <MuiContainer maxWidth={false} style={{marginTop: "70px", paddingLeft: "0px", paddingRight: "0px", height: `${window.innerHeight - 70}px`}}>
            <Divider />
            { children }
            <Outlet/>
          </MuiContainer>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default AdminLayout
