import {Navigate, useLocation} from 'react-router-dom'

interface Props {
  children: JSX.Element
  isAuthenticated: boolean
}

export const RequireAuth = ({children, isAuthenticated}: Props) => {
  let location = useLocation()

  if (!isAuthenticated) {
    return <Navigate to="/sign-in" state={{from: location}} replace/>
  }

  return children
}
