import { Link } from 'react-router-dom'
import Button from '../components/common/Button'
import Container from '@mui/material/Container'
import Text from '../components/common/Text'
import { ReactComponent as Logo } from '../resources/logo.svg'
import Grid from '../components/common/Grid'
import { Avatar } from '@mui/material'
import { PRIMARY_COLOR } from '../constants/colors'

export default function Home() {

  return (
      <Container style={{paddingTop: "100px"}}>
        <Grid container alignItems={"center"} justifyContent={"center"} flexDirection={"column"} rowGap={3}>
          <Avatar style={{height: "100px", width: "100px", backgroundColor: PRIMARY_COLOR}}>
            <Logo/>
          </Avatar>
          <Text style={{fontSize: "3rem", fontWeight: "bold"}}>
            지하철 내비게이션
          </Text>
          <Link to={"/admin"}>
            <Button>
              Admin
            </Button>
          </Link>
        </Grid>
      </Container>
  )
}