import AirlineStopsIcon from '@mui/icons-material/AirlineStops'
import BlockIcon from '@mui/icons-material/Block'
import BurstModeIcon from '@mui/icons-material/BurstMode'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import DashboardIcon from '@mui/icons-material/Dashboard'
import MapIcon from '@mui/icons-material/Map'
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant'
import PeopleIcon from '@mui/icons-material/People'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import RvHookupIcon from '@mui/icons-material/RvHookup'
import SupportAgentIcon from '@mui/icons-material/SupportAgent'
import LogoutIcon from '@mui/icons-material/Logout';
import SignLanguageOutlined from "@mui/icons-material/SignLanguageOutlined";
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { SvgIconTypeMap } from '@mui/material/SvgIcon'

export interface IMenu {
  id: number
  label: string
  to: string
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">>
  indexChild?: string
  children: IMenu[]
}

export const NavigationMenu: IMenu[] = [
  {
    id: 0,
    label: 'Console',
    icon: DashboardIcon,
    to: '/admin',
    children: [
    ],
  },
  {
    id: 1,
    label: '조직관리자',
    icon: CorporateFareIcon,
    to: '/admin/subway-organization',
    children: [
      {
        id: 6,
        label: '기관/공사',
        icon: CorporateFareIcon,
        to: '/admin/subway-organization',
        children: [],
      },
      {
        id: 0,
        label: '호선',
        to: '/admin/subway-organization/line',
        icon: AirlineStopsIcon,
        children: [],
      },
      {
        id: 1,
        label: '역사',
        to: '/admin/subway-organization/station',
        icon: RvHookupIcon,
        children: [],
      }, {
        id: 2,
        label: '비콘',
        to: '/admin/subway-organization/beacon',
        icon: AirlineStopsIcon,
        children: [],
      },
      {
        id: 3,
        label: '번들',
        to: '/admin/subway-organization/bundle',
        icon: RvHookupIcon,
        children: [],
      }, {
        id: 4,
        label: 'Poi 토폴로지 버전',
        to: '/admin/subway-organization/poi-topology-version',
        icon: MapIcon,
        children: [],
      }, {
        id: 5,
        label: '대피',
        to: '/admin/subway-organization/evacuation',
        icon: LogoutIcon,
        children: [],
      }
    ],
  },
  {
    id: 2,
    label: '사용자 유형관리',
    icon: PeopleIcon,
    to: '/admin/user-category',
    children: [],
  },
  {
    id: 3,
    label: '장애물 관리자',
    icon: BlockIcon,
    to: '/admin/obstacle',
    children: [
      {
        id: 0,
        label: '장애물 관리',
        icon: BlockIcon,
        to: '/admin/obstacle',
        children: [],
      },
      {
        id: 1,
        label: '지도',
        to: '/admin/obstacle/map',
        icon: MapIcon,
        children: [],
      },
    ],
  },
  {
    id: 6,
    label: '수어 안내 비디오',
    icon: SignLanguageOutlined,
    to: '/admin/sign-language-video',
    children: [],
  },
  {
    id: 4,
    label: '테마여행',
    icon: BurstModeIcon,
    to: '/admin/theme-trip',
    children: [],
  },
  {
    id: 5,
    label: '고객지원',
    icon: SupportAgentIcon,
    to: '',
    indexChild: "customer-support/qna",
    children: [
      {
        id: 0,
        label: '공지사항',
        to: '/admin/customer-support/qna',
        icon: NotificationImportantIcon,
        children: [],
      },
      {
        id: 1,
        label: '문의내역',
        to: '/admin/customer-support/notice',
        icon: QuestionMarkIcon,
        children: [],
      },
    ],
  },
]
