import {flow, Instance, types} from "mobx-state-tree";
import {SubwayLine, SubwayOrganization, SubwayStation} from "subway-navigation-core";
import {api} from "../api/api";
import {ENDPOINTS} from "../constants/endpoints";
import {IAsyncList} from "../types/async-list";

const defaultData = {
  count: 0,
  rows: []
}

export const DataStore = types.model("DataStore", {
  organization: types.optional(types.frozen<IAsyncList<SubwayOrganization>>(), defaultData),
  line: types.optional(types.frozen<IAsyncList<SubwayLine>>(), defaultData),
  station: types.optional(types.frozen<IAsyncList<SubwayStation>>(), defaultData),
}).views(self => ({
  get organizationOptions() {
    return self.organization.rows.map(s => ({
      label: s.nameKo,
      value: s.uuid,
    }))
  },
  get lineOptions() {
    return self.line.rows.map(s => ({
      label: s.nameKo,
      value: s.uuid,
    }))
  },
  get stationOptions() {
    return self.station.rows.map(s => ({
      label: s.nameKo,
      value: s.uuid,
    }))
  }
}))
  .actions(self => ({
  fetchOrganizations: flow(function* () {
    const res = yield api.get<IAsyncList<SubwayOrganization>>(ENDPOINTS.organization, {limit: undefined})
    if (!res.ok || !res.data) return
    self.organization = res.data
  }),
  fetchStations: flow(function* () {
    const res = yield api.get<IAsyncList<SubwayLine>>(ENDPOINTS.line, {limit: undefined})
    if (!res.ok || !res.data) return

    self.line = res.data
  }),
  fetchLines: flow(function* () {
    const res = yield api.get<IAsyncList<SubwayLine>>(ENDPOINTS.station, {limit: undefined})
    if (!res.ok || !res.data) return

    self.station = res.data
  }),
})).actions(self => ({
  init: async () => {
    await Promise.all([
      self.fetchOrganizations(),
      self.fetchLines(),
      self.fetchStations()
    ])
  }
}))


export type DataStoreType = Instance<typeof DataStore>