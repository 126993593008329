import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Link from '@mui/material/Link'
import IconButton from '@mui/material/IconButton'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { useRootStore } from '../../models/RootStore'
import { usePalleteMode } from '../../providers/PalleteProvider'
import Button from '../common/Button'
import Grid from '../common/Grid'
import DarkModeIcon from '@mui/icons-material/DarkMode'
import LightModeIcon from '@mui/icons-material/LightMode'
import Paper from '@mui/material/Paper'

const Header = observer(() => {
  const authStore = useRootStore('auth')
  const navigate = useNavigate()
  const palette = usePalleteMode()

  const logout = () => {
    authStore.logout(() => navigate('/', { replace: true }))
  }

  return (
    <Paper elevation={0} sx={{ width: '100%', position: 'absolute', minHeight: '70px', display: 'flex', alignItems: 'center', pr: "50px" }} square>
      <Grid flex={1} container alignItems={'center'} height='100%'>
        <Link
          variant='h5'
          color={palette.mode === 'dark' ? '#fff' : '#000'}
          style={{ textDecoration: 'none', marginLeft: '30px' }}
          href={'/'}
        >
          ADMIN
        </Link>
      </Grid>
      <IconButton onClick={palette.toggleMode} sx={{ mr: '40px' }}>
        {palette.mode === 'dark' ? <LightModeIcon /> : <DarkModeIcon />}
      </IconButton>
      <Button onClick={logout}>
        <ExitToAppIcon sx={{ mr: '5px' }} />
        로그아웃
      </Button>
    </Paper>)
})

export default Header
